import { SpecterProducts } from "@prisma/client"
import { useParams } from "@remix-run/react"
import { z } from "zod"

export enum ViewMode {
  Feed = "feed",
  Table = "table",
}

export const PRODUCT_VIEW_MODES: Record<
  SpecterProducts,
  [ViewMode, ...ViewMode[]]
> = {
  [SpecterProducts.company]: [ViewMode.Feed, ViewMode.Table],
  [SpecterProducts.talent]: [ViewMode.Feed, ViewMode.Table],
  [SpecterProducts.stratintel]: [ViewMode.Feed, ViewMode.Table],
  [SpecterProducts.investors]: [ViewMode.Feed, ViewMode.Table],
  [SpecterProducts.fundingRounds]: [ViewMode.Table],
  [SpecterProducts.acquisition]: [ViewMode.Table],
  [SpecterProducts.ipo]: [ViewMode.Table],
  [SpecterProducts.people]: [ViewMode.Feed, ViewMode.Table],
}

export const useViewMode = () => {
  const params = useParams()

  if ("viewMode" in params && typeof params.viewMode === "string") {
    const result = z.nativeEnum(ViewMode).safeParse(params.viewMode)

    if (result.success) {
      return result.data
    }
  }

  return ViewMode.Feed
}
